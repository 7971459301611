const pickup = {
  path: 'pickup/',
  name: 'Pickup',
  redirect: { name: 'PickupOverview' },
  children: [
    {
      path: 'overview',
      name: 'PickupOverview',
      component: () => import('@/views/pickup'),
      meta: {
        title: 'pickup_overview',
      },
    },
    {
      path: 'free-feature/:id',
      name: 'FreeFeatureDetail',
      component: () => import('@/views/pickup/FreeDetail'),
      meta: {
        title: 'free_feature_detail',
      },
    },
    {
      path: 'basic-feature/:id',
      name: 'BasicFeatureDetail',
      component: () => import('@/views/pickup/BasicDetail'),
      meta: {
        title: 'basic_feature_detail',
      },
    },
  ],
};

export default pickup;
