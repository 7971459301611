export const WHITE_LIST = [/^\/login$/i, /^\/forgot-password$/i, /^\/reset-password\/((?:[^\/]+?))(?:\/(?=$))?$/i];
export const DEFAULT_EXPIRE = 1;
export const PERMISSION = Object.freeze({
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
});

export const ROLE = Object.freeze({
  ADMIN: 'ADMIN',
  UPPER: 'UPPER',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  LOWER: 'LOWER',
});

export const ROLES = [
  // { value: 'Admin', name: 'ADMIN' },
  { value: 'UPPER', name: '上位' },
  { value: 'LOWER', name: '下位' },
];
