import { mapState } from 'pinia';
import { useAppStore } from '@/stores';

export default {
  install: app => {
    app.mixin({
      computed: {
        ...mapState(useAppStore, {
          errors: 'errors',
        }),
      },
    });
  },
};
