export const COMPANY_TYPES = [
  {
    value: '卸売業・小売業',
    label: '卸売業・小売業',
  },
  {
    value: '製造業',
    label: '製造業',
  },
  {
    value: '情報通信業',
    label: '情報通信業',
  },
  {
    value: '宿泊業、飲食店',
    label: '宿泊業、飲食店',
  },
  {
    value: '生活関連サービス業',
    label: '生活関連サービス業',
  },
  {
    value: '教育学習支援業',
    label: '教育学習支援業',
  },
  {
    value: '医療、福祉',
    label: '医療、福祉',
  },
  {
    value: 'その他',
    label: 'その他',
  },
];

export const SEARCH_SORT_OPTIONS = [
  { name: '見積ランキング順', value: '見積ランキング順' },
  { name: '最新追加日順', value: '最新追加日順' },
];

export const SEARCH_SORT_LOGGEDIN_OPTIONS = [
  { name: '価格安順', value: '価格安順' },
  { name: '価格高順', value: '価格高順' },
];
