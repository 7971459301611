import { createApp } from 'vue';
import App from '@/views/App';
import router from '@/router';
import i18n from '@/lang';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import '@/styles/app.scss'; // uncomment when build with vue cli
import '@/router/middleware';
import '@/styles/app.scss';
import Mixins from '@/mixinjs/mixin';
import { Amplify } from 'aws-amplify';
import appSyncConfig from './aws-exports';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { createHead, HeadVuePlugin } from '@vueuse/head';

Amplify.configure(appSyncConfig);
const main = createApp(App);
const head = createHead();
/* A performance setting. */
main.config.performance = process.env.NODE_ENV !== 'production';
// Register plugins
const plugins = require.context('@/plugins', false, /\.js$/);
plugins.keys().forEach(file => {
  const plugin = file.replace(/^\.\/(.*)\w*$/, '$1');
  main.use(require(`@/plugins/${plugin}`).default);
});
// Global components
require('@/global-components').default(main);
main.use(router);
main.use(createPinia());
main.use(i18n);
main.use(ElementPlus);
main.use(VueSplide);
main.use(HeadVuePlugin, head);
main.use(head);
main.mixin(Mixins);
main.mount('#app');
