const product = {
  path: 'product/',
  name: 'Product',
  children: [
    {
      path: 'search',
      name: 'Search',
      component: () => import('@/views/search'),
      meta: {
        title: 'search',
      },
    },
    {
      path: 'favorite',
      name: 'FavoriteProducts',
      component: () => import('@/views/buyer/favorite-product'),
      meta: {
        title: 'favorite_product',
      },
    },
    {
      path: 'recent',
      name: 'RecentProduct',
      component: () => import('@/views/buyer/recent-product'),
      meta: {
        title: 'recent_product',
      },
    },
    {
      path: ':id',
      name: 'ProductDetail',
      component: () => import('@/views/product'),
      meta: {
        title: 'product_detail',
      },
    },
  ],
};

export default product;
