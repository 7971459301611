import request from '@/utils/request';
import request_aws from '@/utils/request_aws';

export function getInfo() {
  return request({
    url: '/buyer/api/v1/get-info',
    method: 'get',
  });
}

export function getCategories(form) {
  return request({
    url: '/buyer/api/v1/booth/category/list',
    method: 'post',
    data: form,
  });
}

export function categoryList(form) {
  return request({
    url: '/buyer/api/v1/categories',
    method: 'get',
    params: form,
  });
}
export function upLoadFileToS3(URL, form) {
  return request_aws({
    url: URL,
    method: 'post',
    data: form,
  });
}
