const campaign = {
  path: 'campaign/',
  name: 'Campaign',
  redirect: { name: 'OrganizerCampaignOverview' },
  children: [
    {
      path: 'organizer/overview',
      name: 'OrganizerCampaignOverview',
      component: () => import('@/views/campaign'),
      meta: {
        title: 'organizer_campaign_overview',
      },
    },
    {
      path: 'supplier/:id/overview',
      name: 'SupplierCampaignOverview',
      component: () => import('@/views/campaign'),
      meta: {
        title: 'supplier_campaign_overview',
      },
    },
    {
      path: ':camp_id',
      name: 'CampaignDetail',
      component: () => import('@/views/campaign/Detail'),
      meta: {
        title: 'campaign_detail',
      },
    },
    {
      path: ':id/:camp_id',
      name: 'SupplierCampaignDetail',
      component: () => import('@/views/campaign/Detail'),
      meta: {
        title: 'supplier_campaign_detail',
      },
    },
  ],
};

export default campaign;
