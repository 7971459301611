import SvgIcon from '@libs/components/SvgIcon';

export default app => {
  // register globally
  app.component('SvgIcon', SvgIcon);

  const requireAll = requireContext => requireContext.keys().map(requireContext);
  const req = require.context('./svg', false, /\.svg$/);
  requireAll(req);
};
