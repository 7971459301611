const buyer = {
  path: 'buyer/',
  children: [
    {
      path: 'profile',
      name: 'BuyerProfile',
      component: () => import('@/views/buyer/profile'),
      meta: {
        title: 'buyer_profile',
      },
    },
    {
      path: 'box',
      name: 'BuyerMyPage',
      component: () => import('@/views/buyer/box'),
      meta: {
        title: 'my_box',
      },
    },
    {
      path: 'estimate/:id',
      name: 'EstimateDetail',
      component: () => import('@/views/buyer/box/estimate'),
      meta: {
        title: 'box_detail_estimate',
      },
    },
    {
      path: 'box-detail/:id',
      name: 'BoxDetail',
      component: () => import('@/views/buyer/box/detail'),
      meta: {
        title: 'box_detail',
      },
    },
    {
      path: 'box-detail/:id/create-estimate',
      name: 'EstimateCreate',
      component: () => import('@/views/buyer/box/estimate/Create'),
      meta: {
        title: 'box_detail_estimate',
      },
    },
    {
      path: ':id/create-order',
      name: 'OrderCreate',
      component: () => import('@/views/buyer/box/order/Create'),
      meta: {
        title: 'box_detail_order',
      },
    },
    {
      path: ':id/order-detail',
      name: 'OrderDetail',
      component: () => import('@/views/buyer/box/order'),
      meta: {
        title: 'box_detail_detail',
      },
    },
    {
      path: ':id/purchase',
      name: 'Purchase',
      component: () => import('@/views/buyer/box/purchase'),
      meta: {
        title: 'purchase',
      },
    },
    {
      path: 'all-estimate',
      name: 'BuyerMyPageAllEstimate',
      component: () => import('@/views/buyer/estimate'),
      meta: {
        title: 'all_estimate',
      },
    },
    {
      path: 'order-history',
      name: 'OrderHistory',
      component: () => import('@/views/order-history'),
      meta: {
        title: 'order_history',
      },
    },
    {
      path: 'proposal-history',
      name: 'ProposalHistory',
      component: () => import('@/views/buyer/box/proposal/History'),
      meta: {
        title: 'proposal_history',
      },
    },
    {
      path: 'chat-history',
      name: 'ChatHistory',
      component: () => import('@/views/chat-history'),
      meta: {
        title: 'chat_history',
      },
    },
    {
      path: 'booking-history',
      name: 'BookingHistory',
      component: () => import('@/views/booking-history'),
      meta: {
        title: 'booking_history',
      },
    },
    {
      path: 'proposal/:id',
      name: 'Proposal',
      component: () => import('@/views/buyer/box/proposal'),
      meta: {
        title: 'proposal',
      },
    },
    {
      path: 'my-supplier',
      name: 'MySupplier',
      component: () => import('@/views/buyer/my-supplier'),
      meta: {
        title: 'my_supplier',
      },
    },
  ],
};

export default buyer;
