import { defineStore } from 'pinia';
import { getInfo } from '@/api/v1/common';

export const useBuyerStore = defineStore('buyer', {
  state: () => ({
    info: {},
  }),
  actions: {
    setInfo(info) {
      this.info = info;
    },
    /**
     * getInfoData get data from api
     * @author HaoDT
     */
    async getInfoData() {
      const { data } = await getInfo();
      if (data.success) {
        this.info = data.data;
      } else {
        alert('error');
      }
    },
  },
});
