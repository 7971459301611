import { createI18n } from 'vue-i18n';
import Locale from '@libs/lang/vue-i18n-locales.generated.json';

const messages = {
  en: {
    ...Locale.en,
  },
  vi: {
    ...Locale.vi,
  },
  ja: {
    ...Locale.ja,
  },
};

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('fe_lang') || 'ja',
  messages,
  fallbackLocale: 'ja',
});

export default i18n;
