import moment from 'moment';
import commonSetting from '@wonderline/commonsettings';
// import { HTML_REGEX } from '@/constants';

export default {
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    /**
     * convertSingleByteToDoubleByte
     * @author DangLH
     */
    convertSingleByteToDoubleByte(value) {
      let result = '';
      const fullWidthDigit = 65296;
      const regex = /[0-9]/g;
      if (value) {
        result = value.toString().replace(regex, function (character) {
          return String.fromCharCode(parseInt(character) + fullWidthDigit);
        });
      }
      return result;
    },

    /**
     * changeRoute change route
     * @author DangLH
     * @param route
     */
    changeRoute(route) {
      this.$router.push(route);
    },

    /**
     * formatPrice format price
     * @author DangLH
     * @param number
     * @returns
     */
    formatPrice(number) {
      if (!number) {
        return number;
      }
      return parseInt(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    /**
     * btoa() text to basae64
     * @author DangLH
     * @param val
     * @returns
     */
    convertBase64(val) {
      return window.btoa(val);
    },

    /**
     * subString() string max length
     * @author DangLH
     */
    subString(val, limit) {
      if (val?.length > limit) {
        const newVal = val.substring(0, limit).concat('...');
        return newVal;
      }
      return val;
    },

    /**
     * imageFilePath return file path
     * @author DangLH
     */
    imageFilePath(item) {
      if (item) {
        const prefix = item.slice(0, 4);
        if (prefix === 'http' || item.slice(0, 1) === '/') {
          return item;
        } else {
          return '/'.concat(item);
        }
      }
      return null;
    },

    /**
     * stockStatus() return label status stock
     * @author DangLH
     */
    stockStatus(status) {
      const productStockKey = commonSetting.productStockKey;
      const productStock = commonSetting.productStock;
      let label = '';
      switch (status) {
        case productStockKey.CONTACT:
          label = productStock.CONTACT;
          break;
        case productStockKey.DISCONTINUED:
          label = productStock.DISCONTINUED;
          break;
        case productStockKey.IN_STOCK:
          label = productStock.IN_STOCK;

          break;
        case productStockKey.OUT_OF_STOCK:
          label = productStock.OUT_OF_STOCK;
          break;
        case productStockKey.PRE_ORDER:
          label = productStock.PRE_ORDER;
          break;
      }
      return label;
    },

    /**
     * removeTagHTML() remove tag html
     * @author DangLH
     */
    // removeTagHTML(value) {
    //   return value.replace(HTML_REGEX, '');
    // },

    /**
     * stripHtml strip html
     * @author DangLH
     */
    removeTagHTML(html) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    /**
     * formatNumberTwoLength()
     * @author DangLH
     */
    formatNumberTwoLength(d) {
      return d < 10 ? '0' + d.toString() : d.toString();
    },

    /**
     * replacePrefixTailwind()
     * @author DangLH
     */
    replacePrefixTailwind(string) {
      if (string) {
        return string.replaceAll('tw-', '');
      }
      return '';
    },
    /**
     * imageDefault() return image default if image onload fail
     * @author DangLH
     */
    imageDefault(e) {
      return require('@/assets/images/default-image.jpeg');
    },
    /**
     * imageDefault() return image default if image onload fail
     * @author DangLH
     */
    imageError(e) {
      e.target.src = require('@/assets/images/default-image.jpeg');
    },
  },
};
