const categories = {
  path: 'categories',
  name: 'Categories',
  component: () => import('@/views/category'),
  meta: {
    title: 'categories',
  },
};

export default categories;
