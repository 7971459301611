import { defineStore } from 'pinia';
import { useAppStore, useAuthStore } from '@/stores';
import remove from 'lodash/remove';

export const useRecentProductStore = defineStore('recent-product', {
  state: () => ({
    localData: [],
    localProducts: [],
    index: null,
    appStore: useAppStore(),
    authStore: useAuthStore(),
  }),
  actions: {
    /**
     * setDataLocal get data local storage
     * @author DangLH
     */
    setDataLocal(info) {
      if (this.index >= 0) {
        this.localData.splice(this.index, 1, { buyer_id: this.authStore?.member?.id, products: this.localProducts });
      } else {
        this.localData.push({ buyer_id: this.authStore?.member?.id, products: this.localProducts });
      }
      localStorage.setItem(`${this.appStore.ex_name}-products`, JSON.stringify(this.localData));
    },

    /**
     * addProduct add product
     * @author DangLH
     */
    addProduct(id) {
      // check limit recently products
      if (this.localProducts.length >= 10 && !this.localProducts.includes(id)) {
        // get 9 products recent new
        this.localProducts = this.localProducts.slice(0, 9);
      } else if (this.localProducts.includes(id)) {
        remove(this.localProducts, val => val.toString() === id.toString());
      }
      // push item to recent products
      this.localProducts.unshift(id);
      this.setDataLocal();
    },

    /**
     * removeProduct remove product
     * @author DangLH
     */
    removeProduct(id) {
      remove(this.localProducts, val => val.toString() === id.toString());
      this.setDataLocal();
    },

    /**
     * getDataLocal get data from local storage
     * @author DangLH
     */
    async getDataLocal() {
      this.localData = JSON.parse(localStorage.getItem(`${this.appStore.ex_name}-products`)) || [];
      this.index = this.localData.findIndex(val => {
        return val.buyer_id === this.authStore?.member?.id;
      });
      if (this.index >= 0) {
        this.localProducts = this.localData[this.index].products || [];
      }
    },
  },
});
