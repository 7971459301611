import request from '@/utils/request';

export function organizerDetail(query) {
  return request({
    url: '/buyer/api/v1/organizer/detail',
    method: 'post',
    data: query,
  });
}

export function notificationList(query) {
  return request({
    url: '/buyer/api/v1/booth/notification/list',
    method: 'post',
    data: query,
  });
}

export function notificationDetail(id) {
  return request({
    url: '/buyer/api/v1/booth/notification/detail',
    method: 'post',
    data: { id: id },
  });
}

export function pressReleaseList(query) {
  return request({
    url: '/buyer/api/v1/booth/pre-release/list',
    method: 'post',
    data: query,
  });
}

export function pressReleaseDetail(form) {
  return request({
    url: '/buyer/api/v1/booth/pre-release/detail',
    method: 'post',
    data: form,
  });
}

export function columnList(form) {
  return request({
    url: '/buyer/api/v1/booth/column/list',
    method: 'post',
    data: form,
  });
}

export function columnDetail(form) {
  return request({
    url: '/buyer/api/v1/booth/column/detail',
    method: 'post',
    data: form,
  });
}
