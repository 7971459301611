export const ESTIMATE_APPROVED = 'APPROVED';
export const ESTIMATE_REQUESTED = 'REQUESTED';
export const ESTIMATE_REJECTED = 'REJECTED';

export const EXHIBITION = {
  NO_REGISTER: 'NO_REGISTER',
  WAITING_ACCEPT: 'WAITING_ACCEPT',
  ACCEPT: 'ACCEPT',
  ATTENDING_CONFIRM: 'ATTENDING_CONFIRM',
  REGISTER_PRODUCTS: 'REGISTER_PRODUCTS',
  PRODUCTS_CONFIRM: 'PRODUCTS_CONFIRM',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  IN_PROGRESSING: 'IN_PROGRESSING',
  SCHEDULED: 'SCHEDULED',
  PUBLISH: 'PUBLISH',
  DRAFT: 'DRAFT',
};
export const STATUS_UPLOAD_IMAGE_UPLOADED = 'UPLOADED';
export const STATUS_UPLOAD_IMAGE_DELETED = 'DELETED';

export const STATUS_PRODUCT_SELLING_PUBLISH = 'PUBLISH';
export const STATUS_PRODUCT_SELLING_DRAFT = 'DRAFT';

export const ESTIMATE_STATUS = {
  CREATED: 'CREATED',
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  HAS_ERROR_THREE_DAY: 'HAS_ERROR_THREE_DAY',
  HAS_ERROR_REJECT_AND_APPROVED: 'HAS_ERROR_REJECT_AND_APPROVED',
  HAS_ERROR_CHANGE: 'HAS_ERROR_CHANGE',
};

export const ORDER_STATUS = {
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const EXHIBITION_STATUS = {
  DRAFT: 'DRAFT',
  REGISTERED: 'REGISTERED',
  REJETED: 'REJETED',
  APPROVED: 'APPROVED',
};

export const CATEGORY_TYPE = {
  NEWS: 'NEWS',
};

export const SHIPPING_TYPE = {
  JAPAN: 'JAPAN',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const IS_EST_STATUS = {
  YES: 'YES',
  IMPOSSIBLE: 'IMPOSSIBLE',
};

export const AXIOS_STATUS = {
  ERR_CANCELED: 'ERR_CANCELED',
};
