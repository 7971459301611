import NProgress from 'nprogress'; // progress bar
import router from '@/router';
import { getAccessToken } from '@/utils/auth';
import * as guards from '@/router/middleware/guards';
import getPageTitle from '@/utils/get-page-title';
import { useAuthStore, useAppStore } from '@/stores';
// import { redirect } from '@/setting';
import { BLACK_LIST_NAME, WHITE_LIST_NAME, PREVIEW_LIST } from '@/constants';
// import { matchInArray } from '@libs/utils';
import Cookies from 'js-cookie';

NProgress.configure({ showSpinner: true }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  const BLOCK_LIST = ['/404', '/basic-authen', '/500', '/401'];
  try {
    // exhibition notfound or redirect to blocked list
    if (to.path === '/' || (!to.params.ex_name && !BLOCK_LIST.includes(to.path))) {
      next('/404');
    }
    // chan truy cap cac trang khac tu preview
    if (!PREVIEW_LIST.includes(to.name) && to.query.is_preview) {
      next('/404');
    }
    // chan di chuyen man hinh khi preview
    if (parseInt(from.query.is_preview) && to.path !== '/404') {
      next(from.fullPath);
    }
    // start progress bar
    NProgress.start();
    const title = getPageTitle(to.meta.title);
    document.title = title;
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const hasToken = getAccessToken();
    const basic_authen = Cookies.get('basic_authen');
    if (to.params.ex_name) {
      appStore.setExhibitionInfo(to.params);
    }
    // basic authen
    if (
      basic_authen !== 'true' &&
      !parseInt(to.query.is_preview) &&
      to?.path !== '/basic-authen' &&
      process.env.VUE_APP_BASIC_AUTHEN_CHECK === 'true'
    ) {
      NProgress.done();
      next(`/basic-authen?redirect=${to.path}`);
    }
    // check login
    if (hasToken) {
      if (BLACK_LIST_NAME.includes(to.name)) {
        next(`/${to.params.ex_name}`);
        NProgress.done();
      } else {
        if (appStore.ex_name && !authStore.isLoggedIn) {
          await authStore.memberInfo();
        }
        next();
      }
    } else {
      // reset data auth in vuex
      authStore.reset();
      // next();
      /* has no token*/
      if (WHITE_LIST_NAME.includes(to.name)) {
        // in the free login whitelist, go directly
        next();
      } else {
        // if (!authStore.isLoggedIn) {
        //   await authStore.memberInfo();
        // }
        // other pages that do not have permission to access are redirected to the login page.
        next(`/${to.params.ex_name}/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
    // determine whether the user has logged in
    if (!to.meta.middleware) {
      return next();
    }

    const middleware = to.meta.middleware;

    const context = {
      to,
      from,
      next,
    };
    for (const guard of middleware) {
      const funcNext = guards[guard](context);
      if (funcNext) {
        return next(funcNext);
      }
    }
    return next();
  } catch (e) {
    return NProgress.done();
  }
});

router.afterEach(() => {
  return NProgress.done();
});
