import request from '@/utils/request';
import axios from 'axios';

export function logging(errors) {
  return request({
    url: '/logging',
    method: 'post',
    data: errors,
  });
}

export function commonData(params) {
  return request({
    url: '/buyer/api/v1/common-data',
    method: 'get',
    params: params,
  });
}

export function getKeyImage(data, onUploadProgress) {
  return request({
    url: '/buyer/api/v1/box/proposal/image-s3-presigned-upload',
    method: 'post',
    data,
    onUploadProgress,
  });
}
export function uploadImage(url, data) {
  const formData = new FormData();
  formData.append('acl', data.acl);
  formData.append('key', data.key);
  formData.append('bucket', data.bucket);
  formData.append('X-Amz-Credential', data['X-Amz-Credential']);
  formData.append('X-Amz-Algorithm', data['X-Amz-Algorithm']);
  formData.append('X-Amz-Date', data['X-Amz-Date']);
  formData.append('Policy', data['Policy']);
  formData.append('X-Amz-Signature', data['X-Amz-Signature']);
  formData.append('file', data.file);

  return axios({
    url,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}
