const contact = {
  path: 'contact/',
  name: 'Contact',
  redirect: { name: 'ContactInput' },
  children: [
    {
      path: '',
      name: 'ContactInput',
      component: () => import('@/views/contact'),
      meta: {
        title: 'contact',
      },
    },
    {
      path: 'completed',
      name: 'ContactCompleted',
      component: () => import('@/views/contact/Completed'),
      meta: {
        title: 'contact_completed',
      },
    },
  ],
};

export default contact;
