import { defineStore } from 'pinia';
import { logging } from '@/api/v1/app';
import { organizerDetail } from '@/api/v1/organizer';

export const useAppStore = defineStore('app', {
  state: () => ({
    errors: {},
    ex_name: null,
    exhibition: null,
    dateNow: '',
  }),
  actions: {
    setErrors(errors) {
      this.errors = errors;
    },
    clearErrors() {
      this.errors = {};
    },
    setExhibitionInfo(params) {
      if (params) {
        this.ex_name = params.ex_name;
      } else {
        this.ex_name = null;
      }
    },
    setExhibition(exhibition) {
      this.exhibition = exhibition;
    },
    exhibitionInfo() {
      return new Promise((resolve, reject) => {
        organizerDetail({ exhibition: this.ex_name })
          .then(res => {
            const { data } = res;
            this.exhibition = data?.data?.exhibition;
            resolve(res);
          })
          .catch(err => {
            this.exhibition = {};
            reject(err);
          });
      });
    },
    logging(payload) {
      return new Promise((resolve, reject) => {
        logging(payload)
          .then(res => resolve(res))
          .catch(err => reject(err));
      });
    },
  },
});
