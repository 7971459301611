const topic = {
  path: 'topic/',
  name: 'Topic',
  redirect: { name: 'TopicDetail' },
  children: [
    {
      path: ':id',
      name: 'TopicDetail',
      component: () => import('@/views/topic/Detail'),
      meta: {
        title: 'topic_detail',
      },
    },
  ],
};

export default topic;
