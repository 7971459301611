export const FILE_TYPE = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
export const FILE_TYPE_RESIZE = ['image/jpg', 'image/jpeg', 'image/png'];
export const FILE_TYPE_EDITOR = ['image/jpg', 'image/jpeg', 'image/png'];

export const TYPE_PREVIEW = {
  // organizer
  TOPIC: 'topic',
  PICKUP_BASIC_FEATURE: 'pickup/basic-feature',
  PICKUP_FREE_FEATURE: 'pickup/free-feature',
  NEWS: 'news',
  COLUMN: 'column',
  PRESS_RELEASE: 'press-release',
  // supplier
  SUPPLIER_EXHIBITION_BOOTH: 'supplier/exhibition-booth',
  SUPPLIER_CAMPAIGN: 'campaign',
  SUPPLIER_NEWS: 'supplier-news',
  SUPPLIER_PRODUCT: 'product',
};

export const EXHIBITION_DEFAULT_FOR_PREVIEW = 'exhibition-preview-00000';

export const PDF_TYPE = {
  PROPOSAL: 'PROPOSAL',
  ESTIMATE: 'ESTIMATE',
  ORDER: 'ORDER',
  PURCHASE: 'PURCHASE',
};

export const IMAGES_TYPE = {
  HEADER_IMAGE: 'header_image',
  EYE_CATCH_IMAGE: 'eye_catch_image',
};
