import axios from 'axios';
import router from '@/router';
import md5 from 'md5';
import { getAccessToken } from './auth';
import { useAppStore, useAuthStore } from '@/stores';

// Create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000, // Request timeout
});

// request
service.interceptors.request.use(
  config => {
    const token = getAccessToken() || false;
    if (token) {
      const methods = ['post', 'put', 'patch'];
      config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
      if (process.env.MIX_HASH_KEY && methods.includes(config.method)) {
        config.headers['Hash-Key'] = md5(JSON.stringify(config.data) + process.env.MIX_HASH_KEY);
      }
    }
    const appStore = useAppStore();
    if (Object.keys(appStore.errors).length) {
      appStore.setErrors({});
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    const appStore = useAppStore();
    appStore.setErrors({});
    return response;
  },
  async error => {
    const appStore = useAppStore();
    const res = error.response;
    const originalRequest = error.config;
    if (res.data.errors) {
      appStore.setErrors(res.data.errors);
    }
    switch (true) {
      case res.status === 404:
        if (res.data.message === 'EXHIBITION_NOT_FOUND') {
          appStore.setExhibitionInfo('');
          // window.location.replace = '/404';
          router.replace({ path: '/404' });
        }
        // await router.replace({ path: '/404' });
        break;
      case process.env.NODE_ENV === 'production' && res.status === 501:
        await router.replace('/500');
        break;
      case res.status === 401 && originalRequest.url !== '/v1/refresh-token':
        try {
          const authStore = useAuthStore();
          const passport = await authStore.refreshToKen();
          originalRequest.headers['Authorization'] = 'Bearer ' + passport.refresh_token;
          return service(originalRequest);
        } catch (e) {
          await router.replace({ name: 'Login' });
        }
        break;
    }
    return Promise.reject(error);
  }
);

export default service;
