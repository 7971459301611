import request from '@/utils/request';

export function login(form) {
  return request({
    url: '/buyer/api/v1/login',
    method: 'post',
    data: form,
  });
}

export function refreshToKen(form) {
  return request({
    url: '/refresh-token',
    method: 'post',
    data: form,
  });
}

export function logout(form) {
  return request({
    url: '/buyer/api/v1/logout',
    method: 'get',
    params: form,
  });
}

export function memberInfo(form) {
  return request({
    url: `/buyer/api/v1/get-info`,
    method: 'get',
    params: form,
  });
}

export function registerValidate(form) {
  return request({
    url: '/buyer/api/v1/register/validate',
    method: 'post',
    data: form,
  });
}

export function registerSave(form) {
  return request({
    url: '/buyer/api/v1/register/save',
    method: 'post',
    data: form,
  });
}

export function registerVerifyResend(form) {
  return request({
    url: '/buyer/api/v1/register/verify/resend',
    method: 'post',
    data: form,
  });
}

export function registerVerifyToken(form) {
  return request({
    url: '/buyer/api/v1/register/verify/token',
    method: 'post',
    data: form,
  });
}

export function forgotPassword(form) {
  return request({
    url: '/buyer/api/v1/forgot-password',
    method: 'post',
    data: form,
  });
}

export function forgotPasswordVerifyToken(form) {
  return request({
    url: '/buyer/api/v1/forgot-password/verify/token',
    method: 'post',
    data: form,
  });
}

export function forgotPasswordReset(form) {
  return request({
    url: '/buyer/api/v1/forgot-password/reset',
    method: 'post',
    data: form,
  });
}

export function registerCheckWithdrawal(form) {
  return request({
    url: '/buyer/api/v1/register/check-withdrawal',
    method: 'post',
    data: form,
  });
}
