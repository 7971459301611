import { defineStore } from 'pinia';
import { getCategories } from '@/api/v1/common';
import { useAppStore } from '@/stores';
import i18n from '@/lang';

export const useCategoryStore = defineStore('category', {
  state: () => ({
    productCategories: [],
    pressReleaseCategories: [],
    columnCategories: [],
    newsCategories: [],
    years: [],
    appStore: useAppStore(),
  }),

  actions: {
    async getCategories() {
      try {
        const {
          data: { data },
        } = await getCategories({ exhibition: this.appStore.ex_name });
        this.newsCategories = data.categories.filter(function (category) {
          return category.category_type === 'NEWS';
        });
        this.productCategories = data.categories.filter(function (category) {
          return category.category_type === 'PRODUCT';
        });
        this.productCategories.unshift({
          name: i18n.global.t(`fe.search.all`),
          id: 'all',
        });

        this.columnCategories = data.booth_categories.filter(function (category) {
          return category.type === 'COLUMN';
        });
        this.pressReleaseCategories = data.booth_categories.filter(function (category) {
          return category.type === 'PRE_RELEASED';
        });
      } catch {
        return;
      }
    },
  },
});
