import { isString, isArray } from '@libs/utils';
import settings from '@/setting';
import { nextTick } from 'vue';
import { useAppStore } from '@/stores';

// you can set in settings.js
// errorLog:'production' | ['production', 'development']
const { errorLog: needErrorLog } = settings;
function checkNeed() {
  const env = process.env.NODE_ENV;
  if (isString(needErrorLog)) {
    return env === needErrorLog;
  }
  if (isArray(needErrorLog)) {
    return needErrorLog.includes(env);
  }
  return false;
}

export default app => {
  if (checkNeed()) {
    app.config.errorHandler = async function (err, vm, info) {
      // Don't ask me why I use app.nextTick, it just a hack.
      // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
      const appStore = useAppStore();
      await nextTick();
      await appStore.logging({
        message: err.message,
        stack: err.stack,
        info,
        screen: window.location.href,
        logging: 1, // frontend
      });
      // eslint-disable-next-line no-console
      console.error(err, info);
    };
  }
};
