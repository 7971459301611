const news = {
  path: 'news/',
  name: 'News',
  redirect: { name: 'NewsOverview' },
  children: [
    {
      path: 'overview',
      name: 'NewsOverview',
      component: () => import('@/views/news'),
      meta: {
        title: 'news_overview',
      },
    },
    {
      path: ':id',
      name: 'NewsCategoryDetail',
      component: () => import('@/views/news/Detail'),
      meta: {
        title: 'news_detail',
      },
    },
  ],
};

export default news;
