export const MONTHS = Array.from({ length: 12 }, (_, i) => i + 1).map(item => ({
  label: item + '月',
  value: item,
}));

export const DAY_OPTIONS = [
  {
    value: 'TO_DAY',
    label: '今日',
  },
  {
    value: 'LAST_DAY',
    label: '昨日',
  },
  {
    value: 'LAST_7_DAYS',
    label: '7日間',
  },
  {
    value: 'LAST_1_MONTH',
    label: '1ヶ月',
  },
  {
    value: 'LAST_6_MONTHS',
    label: '6ヶ月',
  },
  {
    value: 'LAST_1_YEAR',
    label: '1年',
  },
];

export const BOOTH_NOTIFICATION_TYPE = [
  { value: 'SUPPLIER', label: 'サプライヤー' },
  { value: 'BUYER', label: 'バイヤー' },
];
