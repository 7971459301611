import { ElMessage } from 'element-plus';
import _ from 'lodash';
import { TYPE_PREVIEW, EXHIBITION_DEFAULT_FOR_PREVIEW } from '@libs/constants';

/**
 * Get query object from URL
 * @param {string} url
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });

  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s++;
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2;
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
      i--;
    }
  }
  return s;
}

/**
 * Remove invalid (not equal true) elements from array
 *
 * @param {Array} actual
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}

/**
 * Parse params from URL and return an object
 *
 * @param {string} url
 */
export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') +
      '"}'
  );
}

/**
 * @param {string} val
 */
export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;

  return div.textContent || div.innerText;
}

/**
 * Merges two  objects, giving the last one precedence
 *
 * @param {Object} target
 * @param {Object} source
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });

  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;
  const later = function () {
    // According to the last trigger interval
    const last = new Date().getTime() - timestamp;

    // The last time the wrapped function was called, the interval is last less than the set time interval wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // If it is set to immediate===true, since the start boundary has already been called, there is no need to call it here.
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = args = null;
        }
      }
    }
  };

  return function () {
    args = arguments; // "arguments" của func
    context = this;
    timestamp = new Date().getTime();
    const callNow = immediate && !timeout;
    // If the delay does not exist, reset the delay
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });

  return targetObj;
}

/**
 * @param {Object[]} arr
 * @returns {Object[]}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + '';
  const randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) {
    ele.className += ' ' + cls;
  }
}

/**
 * Remove class from element
 *
 * @param {HTMLElement} elm
 * @param {String} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/**
 *
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 *
 * @param string
 * @param expressions
 * @returns {boolean}
 */
export function matchInArray(string, expressions) {
  const len = expressions.length;
  let i = 0;

  for (; i < len; i++) {
    if (string.match(expressions[i])) {
      return true;
    }
  }

  return false;
}

export function checkNested(obj /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !Object.prototype.hasOwnProperty.call(obj, args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}

export function downloadTagA(path, filename) {
  const element = document.createElement('a');
  element.setAttribute('href', path);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/**
 * swap data
 * @param data
 * @param x
 * @param y
 * @returns {*}
 */
export function swap(data, x, y) {
  const origin = data[x];
  data[x] = data[y];
  data[y] = origin;

  return data;
}

/**
 *
 * @param time
 * @param label
 * @returns {string|*}
 */
export function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * btoa() text to base64
 * @author DangLH
 */
export const convertBase64 = val => {
  return window.btoa(val);
};

/**
 * isApiNotShowMessageCommon check api not show message common
 * @author HaoDT
 */
export const isApiNotShowMessageCommon = (arrayList, urlApi) => {
  for (const item of arrayList) {
    if (urlApi.includes(item)) {
      return true;
    }
  }
  return false;
};

/*
 * checkCharacterTwoByte checking the character is two byte
 * @param {*} value string
 * @return boolean
 * @author SuTV
 */
export function checkCharacterTwoByte(value) {
  let flag = false;
  const regex = /^[Ａ-ｚぁ-んァ-ン０-９\s]+$/;
  for (let i = 0; i < value?.length; i++) {
    if (regex.test(value[i])) {
      flag = true;
      break;
    }
  }

  return flag;
}

/*
 * checkCharacterJP checking the character is japan
 * @param {*} value string
 * @return boolean
 * @author SuTV
 */
export function checkCharacterJP(value) {
  let flag = false;
  const regex = /^[Ａ-ｚぁ-んァ-ン一-龥０-９\s]+$/;
  for (let i = 0; i < value?.length; i++) {
    if (regex.test(value[i])) {
      flag = true;
      break;
    }
  }

  return flag;
}

/**
 * isMockup is show message if mockup
 * @author HaoDT
 */
export const isMockup = () => {
  ElMessage.error('現在機能実装中！');
};

/**
 * getFileName
 * @author SuTV
 */
export function getFileName(fileName) {
  return fileName && (fileName.match(/[^\\/]+\.[^\\/]+$/) || []).pop();
}

/**
 * borderError border error render class
 * @author HaoDT
 */
export const borderError = (rules, field) => {
  return Object.keys(rules).includes(field) ? 'is-error' : '';
};
/**
 * convertDoubleByteToSingleByte Convert double byte integer to single byte
 * @author SuTV
 */
export function convertDoubleByteToSingleByte(value) {
  let result = '';
  const regex = /[\uFF10-\uFF19]/g;
  if (value) {
    result = value.toString().replace(regex, function (character) {
      return String.fromCharCode(character.charCodeAt(0) - 65248);
    });
  }

  return result;
}

/**
 * toBase64 convert file to base64
 * @author SuTV
 */
export const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

/**
 * isBase64 checking the file is base64
 * @author SuTV
 */
export function isBase64(value) {
  const regex =
    '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)?(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?';
  return new RegExp('^' + regex + '$', 'gi').test(value);
}

export function checkNumberOneAndTwoByte(string) {
  if (!string) {
    return true;
  }
  const regex = /^[０-９0-9]+$/;

  return regex.exec(string);
}
/**
 * groupByViaObject group data
 * @author DinhPL
 */
export const groupByViaObject = (datas, key) => {
  return _.mapValues(_.groupBy(datas, key), clist => clist.map(val => _.omit(val, key)));
};

/**
 * imageToBase64 get image base64
 * @author HaoDT
 */
export const imageToBase64 = async file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

/**
 * roundTimeQuarterHour route time to 1/4 hour
 * @author HaoDT
 */
export function roundTimeQuarterHour(time) {
  var timeToReturn = new Date(time);
  timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 15) * 15);
  if ([0, 15, 30, 45].includes(new Date(time).getMinutes())) {
    timeToReturn.setMinutes(timeToReturn.getMinutes() + 15);
  }
  return timeToReturn;
}

/**
 * handleCatchError handle catch error
 * @author HaoDT
 */
export const handleCatchError = e => {
  // eslint-disable-next-line no-console
  console.log(e);
  let errors = {};
  if (e.response?.data?.errors) {
    errors = e.response?.data?.errors;
  }
  if (e.response?.data?.message) {
    errors = {
      message: [e.response?.data?.message],
    };
  }
  return errors;
};

/**
 * disabledDateElDatePicker disabled date of date picker
 * @author HaoDT
 */
export const disabledDateElDatePicker = time => {
  const dateOffset = 24 * 60 * 60 * 1000 * 1; // 1 days
  const now = new Date(getTimeNowJP());
  now.setTime(now.getTime() - dateOffset);
  return time.getTime() < now;
};

/**
 * functionForSortJavascriptByAttributeAsc function for sort javascript by attribute asc
 * @author HaoDT
 */
export const functionForSortJavascriptByAttributeAsc = (item1, item2, attribute, sortTypeASC = true) => {
  if (sortTypeASC) {
    if (item1[attribute] < item2[attribute]) {
      return -1;
    }
    if (item1[attribute] > item2[attribute]) {
      return 1;
    }
    return 0;
  } else {
    if (item1[attribute] > item2[attribute]) {
      return -1;
    }
    if (item1[attribute] < item2[attribute]) {
      return 1;
    }
    return 0;
  }
};

/**
 * handleViewProduct handle view product in frontend
 * @author HaoDT
 */
export const handleViewProduct = (id, supplierEnName = '') => {
  const base64Id = convertBase64(id);
  const url = `/${supplierEnName}/product/${base64Id}`;
  window.open(url, '_blank');
};

/**
 * scrollToTop scroll to top page
 * @author SuTV
 */

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

/**
 * handleErrorForm return the form of message
 * @params error.response.data
 * @author SuTV
 */
export const handleErrorForm = error => {
  const message = error.message ? { global: [error.message] } : error.errors;
  return { ...message };
};

/**
 * getTimeNowJP get time now in japan
 * @author HaoDT
 */
export const getTimeNowJP = () => {
  return new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });
};

/**
 * getDateArray get range date array from start date to end date
 * @author HaoDT
 */
/*eslint-disable */
export const getDateArray = (start, end) => {
  end = new Date(end);
  const arr = [];
  start = new Date(start);

  while (start < end) {
    arr.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }
  return arr;
};

/**
 * stripHtml strip html
 * @author HaoDT
 */
export const stripHtml = html => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

/**
 * getUrlPreview get url preview
 * @author HaoDT
 */
export const getUrlPreview = (exName, id, type, supplierId = null) => {
  // if is preview screen of supplier else is preview screen of organizer
  if (
    [TYPE_PREVIEW.SUPPLIER_EXHIBITION_BOOTH, TYPE_PREVIEW.SUPPLIER_CAMPAIGN, TYPE_PREVIEW.SUPPLIER_NEWS].includes(type)
  ) {
    exName = EXHIBITION_DEFAULT_FOR_PREVIEW;
    // handle custom b/c supplier campaign url preview have 2 params
    if (type === TYPE_PREVIEW.SUPPLIER_CAMPAIGN) {
      return `${process.env.MIX_BASE_APP_URL}/${exName}/${type}/${convertBase64(supplierId)}/${convertBase64(
        id
      )}?is_preview=1&supplier_preview=1`;
    }
    return `${process.env.MIX_BASE_APP_URL}/${exName}/${type}/${convertBase64(id)}?is_preview=1`;
  }
  return `${process.env.MIX_BASE_APP_URL}/${exName}/${type}/${convertBase64(id)}?is_preview=1`;
};

/**
 * scrollToBottom scroll to bottom page
 * @author HaoDT
 */
export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

/**
 * downloadFile
 * @author DinhPL
 */
export const downloadFile = url => {
  const link = document.createElement('a');
  link.href = url;
  link.download = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * redirectToErrorPage redirect to error page
 * @author HaoDT
 */
export const redirectToErrorPage = router => {
  router.push('/error');
};

/**
 * disabledDate Checks if a date falls outside the range of one year ago from the current date to the current date.
 * @returns {boolean} Returns true if the date falls outside the allowed range, false otherwise.
 * @author SuTV
 */
export const disabledDate = date => {
  const now = new Date(getTimeNowJP());
  const startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
  return date < startDate || date > now;
};

/**
 * showComingSoon show the coming soon message
 * @author SuTV
 */
export const showComingSoon = () => {
  ElMessage({
    message: '現在機能実装中！',
    grouping: true,
    type: 'warning',
    offset: 80,
    duration: 2000,
  });
};
