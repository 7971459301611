import moment from 'moment';
import { pluralize } from './common';
import { PDF_TYPE } from '@libs/constants';
import i18n from '@/lang';

export function secondToDay(second) {
  return second / (24 * 60 * 60);
}

/**
 * convertCFormat convert cFormat 'YYYY/MM/DD HH:mm'
 * @author HaoDT
 */
export function convertCFormat(cFormat) {
  return cFormat
    .replaceAll('{y}', 'YYYY')
    .replaceAll('{m}', 'MM')
    .replaceAll('{d}', 'DD')
    .replaceAll('{h}', 'HH')
    .replaceAll('{i}', 'mm')
    .replaceAll('{s}', 'ss');
}

/**
 *
 * @param time
 * @param cFormat '{y}-{m}-{d} {h}:{i}:{s}'
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (time && typeof time === 'string' && time.includes('0Z')) {
    return moment(time).utcOffset('+09:00').format(convertCFormat(cFormat));
  } else {
    if (arguments.length === 0 || !time) {
      return null;
    }

    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
      date = time;
    } else {
      if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
        time = parseInt(time) * 1000;
      }
      if (typeof time === 'number' && time.toString().length === 10) {
        time = time * 1000;
      }

      date = new Date(time);
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    return format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][value];
      }
      if (result.length > 0 && value < 10) {
        value = '0' + value;
      }

      return value || 0;
    });
  }
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return 'Just now';
  } else if (diff < 3600) {
    // less 1 hour
    return pluralize(Math.ceil(diff / 60), ' minute') + ' ago';
  } else if (diff < 3600 * 24) {
    return pluralize(Math.ceil(diff / 3600), ' hour') + ' ago';
  } else if (diff < 3600 * 24 * 2) {
    return '1 day ago';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      pluralize(d.getMonth() + 1, ' month') +
      ' ' +
      pluralize(d.getDate(), ' day') +
      ' ' +
      pluralize(d.getHours(), ' day') +
      ' ' +
      pluralize(d.getMinutes(), ' minute')
    );
  }
}

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * Format date year-month-day
 * @param {Date} date
 */
export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 *q
 * @param num
 * @param digits
 * @returns {string}
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * format a thousand
 * @param num
 * @returns {string}
 */
export function toThousandFilter(num) {
  if (num) {
    return num.toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
  }
  return '';
}
/**
 * numberTruncating rounding number
 * Ex: 1.20 = 1.2, 1.21346 = 1.21
 * @param num
 * @returns {string}
 */
export function numberTruncating(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

/**
 * format 1000 to "k"
 * @param num
 * @returns {string}
 */
export function kFormatter(num) {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
}

export function parseTimeToTz(date, timeZone, format = '{y}-{m}-{d}') {
  return parseTime(new Date(new Date(date).toLocaleString('en-US', { timeZone })), format);
}

/**
 * parseTimeToTzJapan
 * @author SuTV
 */
export function parseTimeToTzJapan(date) {
  if (date && date.includes('0Z')) {
    return moment(date).utcOffset('+09:00').format('YYYY/MM/DD HH:mm');
  } else {
    return parseTime(date, '{y}/{m}/{d} {h}:{i}');
  }
}

/**
 * checkOnlyNumber check only number of string
 * @author HaoDT
 */
export const checkOnlyNumber = string => {
  if (!string) {
    return true;
  }
  const regex = /^\d+$/;
  return regex.exec(string);
};

/**
 * checkOnlyNumber check only number of string
 * @author DangLH
 */
export const checkOnlyFloatNumber = string => {
  if (!string) {
    return true;
  }
  const regex = /^[+-]?\d+(\.\d+)?$/;
  return regex.test(string);
};

/**
 * convertSingleByteToDoubleByte
 * @author DangLH
 */
export const convertSingleByteToDoubleByte = value => {
  let result = '';
  const regex = /[0-9]/g;
  if (value) {
    result = value.toString().replace(regex, function (character) {
      return String.fromCharCode(parseInt(character) + 65296);
    });
  }
  return result;
};

/**
 * stripHtml strip html
 * @author DangLH
 */
export const subString = (val, limit = 20) => {
  if (val?.length > limit) {
    const newVal = val.substring(0, limit).concat('...');
    return newVal;
  }
  return val;
};

/**
 * convertThousandNumber
 * @author SuTV
 */
export function convertThousandNumber(num) {
  if (num) {
    return num.toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
  } else {
    return num;
  }
}

/*
 * PDFName return PDF name
 * @author DangLH
 */
export function PDFName(type, id, date = new Date()) {
  let name = '';
  switch (type) {
    case PDF_TYPE.PROPOSAL:
      name = i18n.global.t('common.PDF.proposal');
      break;
    case PDF_TYPE.PURCHASE:
      name = i18n.global.t('common.PDF.purchase');
      break;
    case PDF_TYPE.ESTIMATE:
      name = i18n.global.t('common.PDF.estimate');
      break;
    default:
      name = i18n.global.t('common.PDF.order');
      break;
  }
  return name + '_' + id + '_' + parseTime(date, '{y}{m}{d}{h}{i}');
}
