import { defineStore } from 'pinia';
import { login, logout, memberInfo, refreshToKen } from '@/api/v1/auth';
import { getRefreshToken, removeAccessToken, removeRefreshToken, setAccessToken, setRefreshToken } from '@/utils/auth';
import { secondToDay } from '@libs/utils';
import { useAppStore } from '@/stores';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    access_token: '',
    refresh_token: '',
    member: {},
    isLoggedIn: false,
    appStore: useAppStore(),
  }),
  actions: {
    login(payload) {
      return new Promise((resolve, reject) => {
        login(payload)
          .then(res => {
            const {
              data: { data: passport },
            } = res;
            const { access_token, expires_in, refresh_token } = passport;
            this.access_token = access_token;
            this.refresh_token = refresh_token;
            setAccessToken(access_token, secondToDay(expires_in));
            setRefreshToken(refresh_token);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    memberInfo() {
      return new Promise((resolve, reject) => {
        memberInfo({ exhibition: this.appStore.ex_name })
          .then(res => {
            const { data } = res;
            this.member = data.data;
            this.isLoggedIn = !!Object.keys(data.data).length;
            resolve(res);
          })
          .catch(err => {
            this.member = {};
            this.isLoggedIn = false;
            reject(err);
          });
      });
    },
    refreshToKen() {
      return new Promise((resolve, reject) => {
        refreshToKen({ refresh_token: getRefreshToken(), exhibition: this.appStore.ex_name })
          .then(res => {
            const {
              data: { data: passport },
            } = res;
            const { access_token, expires_in, refresh_token } = passport;
            this.access_token = access_token;
            this.refresh_token = refresh_token;
            setAccessToken(access_token, secondToDay(expires_in));
            setRefreshToken(refresh_token);
            resolve(passport);
          })
          .catch(err => {
            this.access_token = '';
            this.refresh_token = '';
            removeAccessToken();
            removeRefreshToken();
            reject(err);
          });
      });
    },
    reset() {
      this.$reset();
      removeAccessToken();
      removeRefreshToken();
    },
    logout() {
      return new Promise((resolve, reject) => {
        logout({ exhibition: this.appStore.ex_name })
          .then(res => {
            this.$reset();
            removeAccessToken();
            removeRefreshToken();
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
});
