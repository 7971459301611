export const ESTIMATE_STATUS = {
  CREATED: 'CREATED',
  REQUESTED: 'REQUESTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  HAS_ERROR_THREE_DAY: 'HAS_ERROR_THREE_DAY',
  HAS_ERROR_REJECT_AND_APPROVED: 'HAS_ERROR_REJECT_AND_APPROVED',
  HAS_ERROR_CHANGE: 'HAS_ERROR_CHANGE',
  HAS_ERROR_REJECT: 'HAS_ERROR_REJECT',
};

export const STOCK_STATUS = {
  DISCONTINUED: 'DISCONTINUED',
  IN_STOCK: 'IN_STOCK',
  PRE_ORDER: 'PRE_ORDER',
  CONTACT: 'CONTACT',
};

export const SUP_PRODUCT_CONDITION = {
  RETURN_POLICY: 'RETURN_POLICY',
  TERM_OF_SALE: 'TERM_OF_SALE',
  NOTICE_POINT: 'NOTICE_POINT',
};

export const CATEGORY_TYPE = {
  PRE_RELEASED: 'PRE_RELEASED',
  COLUMN: 'COLUMN',
};

export const MEETING_TIME = {
  NEGOTIATION: 'NEGOTIATION',
};
