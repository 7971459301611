import { createRouter, createWebHistory } from 'vue-router';
import column from './modules/column';
import pressRelease from './modules/press-release';
import news from './modules/news';
import supplier from './modules/supplier';
import rankingProducts from './modules/ranking-products';
import categories from './modules/categories';
import pickup from './modules/pickup';
import buyer from './modules/buyer';
import organizer from './modules/organizer';
import topic from './modules/topic';
import contact from './modules/contact';
import product from './modules/product';
import campaign from './modules/campaign';

export const routes = [
  {
    path: '/:ex_name/pdf/estimate/:id',
    component: () => import('@/views/pdf/Estimate'),
    name: 'EstimatePDF',
  },
  {
    path: '/:ex_name/pdf/order/:id',
    component: () => import('@/views/pdf/Order'),
    name: 'OrderPDF',
  },
  {
    path: '/:ex_name/pdf/proposal/:id',
    component: () => import('@/views/pdf/Proposal'),
    name: 'ProposalPDF',
  },
  {
    path: '/:ex_name/',
    component: () => import('@/layout'),
    children: [
      {
        path: '',
        name: 'Top',
        component: () => import('@/views/top-page/Top'),
        meta: {
          title: 'top',
        },
      },
      {
        path: 'company-info',
        name: 'CompanyInfo',
        component: () => import('@/views/spri-company'),
        meta: {
          title: 'company_info',
        },
      },
      news,
      column,
      pressRelease,
      supplier,
      rankingProducts,
      categories,
      pickup,
      buyer,
      organizer,
      topic,
      contact,
      product,
      campaign,
      {
        path: 'guild',
        name: 'UIGuild',
        component: () => import('@/views/UIGuild'),
      },

      // FE020
      {
        path: 'coming-soon/:id?',
        name: 'ComingSoon',
        component: () => import('@/views/errors/ComingSoon'),
      },
    ],
  },
  {
    path: '/:ex_name/',
    component: () => import('@/layout/LayoutNoHeader'),
    children: [
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/auth/register'),
        meta: {
          title: 'register',
        },
      },
      {
        path: 'register-verify-resend/:email',
        name: 'RegisterTempCompleted',
        component: () => import('@/views/auth/register/TempCompleted'),
        meta: {
          title: 'register_temp_completed',
        },
      },
      {
        path: 'register-verify/:token',
        name: 'RegisterCompleted',
        component: () => import('@/views/auth/register/Completed'),
        meta: {
          title: 'register_completed',
        },
      },
    ],
  },
  {
    path: '/:ex_name/',
    component: () => import('@/layout/LayoutNoFooter'),
    children: [
      {
        path: 'forgot-password',
        name: 'PasswordForgot',
        component: () => import('@/views/auth/PasswordForgot'),
        meta: {
          title: 'forgot_password',
        },
      },
      {
        path: 'reset-password/:token',
        name: 'PasswordReset',
        component: () => import('@/views/auth/PasswordReset'),
        meta: {
          title: 'reset_password',
        },
      },
      {
        path: 'register/pw-comp',
        name: 'PasswordConfirm',
        component: () => import('@/views/auth/PasswordConfirm'),
        meta: {
          title: 'password_confirm',
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        meta: {
          title: 'buyer_login',
        },
      },
      {
        path: '404',
        name: 'ex404',
        component: () => import('@/views/errors/404'),
      },
      {
        path: '500',
        name: 'ex500',
        component: () => import('@/views/errors/500'),
      },
      {
        path: '401',
        name: 'ex401',
        component: () => import('@/views/errors/401'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layout/LayoutNotFound'),
    children: [
      {
        path: '401',
        name: '401',
        component: () => import('@/views/errors/401'),
      },
      {
        path: '404',
        name: '404',
        component: () => import('@/views/errors/404'),
      },
      {
        path: '500',
        name: '500',
        component: () => import('@/views/errors/500'),
      },
    ],
  },
  {
    path: '/basic-authen',
    component: () => import('@/views/BasicAuthen'),
    name: 'BasicAuth',
  },
  { path: '/:pathMatch(.*)*', redirect: '/404' },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return { el: to.hash };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
