export const WHITE_LIST_NAME = [
  '404',
  '500',
  '401',
  'Login',
  'PasswordForgot',
  'PasswordReset',
  'Top',
  'Search',
  'ProductDetail',
  'Register',
  'RegisterTempCompleted',
  'RegisterCompleted',
  'UIGuild',
  'BasicAuth',
  'Contact',
  'ContactInput',
  'ContactCompleted',
  'SupplierExhibitionBooth',
  'SupplierCompanyInfo',
  'SupplierOverview',
  'SupplierRegisterCompleted',
  'SupplierRegister',
  'CampaignDetail',
  'Campaign',
  'CampaignOverview',
  'OrganizerCampaignOverview',
  'SupplierCampaignOverview',
  'SupplierCampaignDetail',
  'News',
  'NewsOverview',
  'NewsCategoryDetail',
  'OrganizerCompanyInfo',
  'Categories',
  'Column',
  'ColumnOverview',
  'ColumnDetail',
  'Pickup',
  'PickupOverview',
  'PressRelease',
  'PressReleaseOverview',
  'PressReleaseDetail',
  'FreeFeatureDetail',
  'BasicFeatureDetail',
  'Topic',
  'TopicDetail',
  'PasswordConfirm',
  'ex404',
  'ex500',
  'ex401',
  'CompanyInfo',
  'EstimatePDF',
];

export const BLACK_LIST_NAME = ['Login', 'Register', 'RegisterTempCompleted', 'RegisterCompleted'];
export const PREVIEW_LIST = [
  'TopicDetail',
  'PressReleaseDetail',
  'BasicFeatureDetail',
  'FreeFeatureDetail',
  'NewsCategoryDetail',
  'ColumnDetail',
  'ProductDetail',
  'SupplierCampaignDetail',
  'SupplierExhibitionBooth',
];

export const LOGGEDIN_BLOCK_LIST = [
  /^\/login$/i,
  /^\/register$/i,
  /^\/register-verify-resend\/[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i,
  /^\/register-verify\/.*$/i,
];

export const BUYER_STATUS = {
  SENT_MAIL: 'SENT_MAIL',
  WITHDRAWAL: 'WITHDRAWAL',
  STOP: 'STOP',
  ACTIVATED: 'ACTIVATED',
  LOCKED: 'LOCKED',
};
