const organizer = {
  path: 'organizer/',
  name: 'Organizer',
  redirect: { name: 'OrganizerOverview' },
  children: [
    {
      path: 'company-info',
      name: 'OrganizerCompanyInfo',
      component: () => import('@/views/organizer/company-info'),
      meta: {
        title: 'organizer_company_info',
      },
    },
  ],
};

export default organizer;
